<template>
  <van-notice-bar
    left-icon="volume-o"
    text="暂不支持支付宝"
  />
  <div style="padding:15px;margin:7px 0 0 0;background:#fff;">
    <van-row>
      <van-col span="12">
        <h5>商户余额</h5>
        <h3 style="color:#ff5000;margin:7px 0 0 0;">{{ balance }}</h3>
      </van-col>
      <van-col span="12" style="text-align:right;">
        <van-button type="primary" @click="show = true">预约提现</van-button>
      </van-col>
    </van-row>
  </div>
  <!-- <div style="padding:15px;margin:7px 0 0 0;background:#fff;" v-if="sum.todayTotalAmount > 0">
    <small>今日已提现 &yen;{{ sum.todayTotalAmount }}</small>
  </div> -->
  <van-cell-group title="温馨提示">
    <van-cell value="商户余额是您的微信商户号的到账金额，点击【预约提现】按钮，即可发起微信商户号的提现请求。" />
    <van-cell value="该预约提现申请提交后，微信商户系统将在次日发起提现，预计当日到账。具体到账时间以实际银行处理为准。" />
  </van-cell-group>
  <van-cell-group title=" ">
    <van-cell title="资金明细" is-link :to="{ name: 'Fund'}" />
    <!-- <van-cell title="分润记录" is-link :to="{ name: 'Commission'}" /> -->
  </van-cell-group>
  <van-dialog v-model:show="show" title="身份验证" show-cancel-button :beforeClose="onConfirm">
    <van-field
      v-model="sms"
      center
      clearable
      label="验证码"
      placeholder="请输入验证码"
      label-width="4.2em"
    >
      <template #button>
        <van-button size="mini" type="primary" @click="onSendCaptcha" :disabled="timeOut !== ''">
          <span v-if="timeOut">{{codetimer}}S后获取</span>
          <span v-else>发送验证码</span>
        </van-button>
      </template>
    </van-field>
    <van-field
      v-model="amount"
      label="取现金额"
      placeholder="请输入金额"
      label-width="4.2em"
    />
  </van-dialog>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import axios from 'axios'

export default {
  components: {
  },
  setup () {
    const req = inject('req')
    const Cookies = inject('Cookies')
    const state = reactive({
      balance: 0,
      sms: '',
      show: false,
      amount: '',
      userId: Cookies.get('userId'),
      storeId: Cookies.get('storeId'),
      phone: '',
      rule: {},
      sum: {
        todayTotalCount: 0,
        todayTotalAmount: 0
      },
      timeOut: '',
      codetimer: 180
    })
    const onSendCaptcha = () => {
      if (state.timeOut) return
      req.post('/util.sendCaptcha', {
        phone: state.phone
      }).then(res => {
        if (res.code === 0) {
          Toast('短信已发送')
          setTimeOut()
        } else {
          Toast(res.msg)
        }
      })
    }
    const setTimeOut = () => {
      state.timeOut = setInterval(() => {
        if (state.codetimer > 1) {
          state.codetimer -= 1
        } else {
          state.codetimer = 180
          clearTimeout(state.timeOut)
          state.timeOut = ''
        }
      }, 1000)
    }
    const onConfirm = (action) =>
      new Promise((resolve) => {
        if (action === 'confirm') {
          Toast.loading({ forbidClick: true })
          req.post('/subWithdraw', {
            userId: state.userId,
            captcha: state.sms,
            storeId: state.storeId,
            amount: state.amount
          }).then(res => {
            Toast.clear()
            if (res.code === 0) {
              resolve(true)
              Toast.success()
              init()
            } else {
              resolve(false)
              Toast(res.msg)
              if (res.code !== 99) {
                state.codetimer = 180
                clearTimeout(state.timeOut)
                state.timeOut = ''
              }
            }
          })
        } else {
          // 拦截取消操作
          resolve(true)
        }
      })
    // const onConfirm = () => {
    //   Toast.loading({ forbidClick: true })
    //   req.post('/fund.withdrawal', {
    //     userId: state.userId,
    //     captcha: state.sms,
    //     amount: state.amount
    //   }).then(res => {
    //     Toast.clear()
    //     if (res.code === 0) {
    //       Toast.success()
    //       init()
    //     } else {
    //       Toast(res.msg)
    //       if (res.code !== 99) {
    //         state.codetimer = 180
    //         clearTimeout(state.timeOut)
    //         state.timeOut = ''
    //       }
    //     }
    //   })
    // }
    const init = () => {
      req.post('/user.get', {
        userId: state.userId
      }).then(res => {
        state.phone = res.data.phone
      })
      req.post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        if (res.data.storeMerchant) {
          getBalance(res.data.storeMerchant.subMchid)
        }
      })
      // req.post('/fund.getWithdrawalRule', {
      // }).then(res => {
      //   state.rule = res.data
      // })
      // req.post('/fund.sumWithdrawalByUser', {
      //   userId: state.userId
      // }).then(res => {
      //   state.sum = res.data
      // })
    }
    const getBalance = (subMchid) => {
      axios({
        method: 'POST',
        url: process.env.VUE_APP_URL + '/subnowbalance',
        data: subMchid,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).then(({ data }) => {
        state.balance = data.data.availableAmount
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSendCaptcha,
      onConfirm,
      setTimeOut
    }
  }
}
</script>

<style scoped>
</style>
